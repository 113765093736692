import React, { Component } from "react";
import { NavBar } from "../../components/Navbar";
import HeroSmart from "../../components/HeroSmart";
import Footer from "../../components/Footer";
import Signup from "../../components/Signup";
import SmartSlider from "../../components/Slider";
import axios from "axios";
import LayoutBasic from "../../components/LayoutBasic";
import Toast from "../../components/Toast";
import styled from "styled-components";

export default class Commercial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: props.user,
      navPos: "absolute",
      toastMsg: [],
      toastShow: false,
      sliderArray: [],
      mobileNavHidden: true,
    };

    // bind signup and toast
    this.handleSignupResult = this.handleSignupResult.bind(this);
    this.toggleToast = this.toggleToast.bind(this);
    this.toggleMobileNav = this.toggleMobileNav.bind(this);
  }

  componentDidMount() {
    // reset window defaults, should have used redux, this is on every page.
    window.scrollTo(0, 0);
    this.setState({ mobileNavHidden: true });

    // call database for image array to be used in slider
    axios.get("/cms/kitchenbathvanity").then((collectData) => {
      this.setState({ allImageTables: collectData.data });

      let arrayOfObjects = [];

      this.state.allImageTables.forEach((table) => {
        if (table.showcase) {
          this.setState({ arrayOfImages: table.showcase });
        }

        if (table.showcaseGrid) {
          // console.log(table.showcaseGrid);

          for (let i = 0; i < table.showcaseGrid.length; i++) {
            let newObjectItem = {
              original: `${table.showcaseGrid[i]}`,
              thumbnail: `${table.showcaseGrid[i]}`,
            };
            arrayOfObjects.push(newObjectItem);
          }
        }
      });

      this.setState({ sliderArray: arrayOfObjects });
    });
  }

  handleSignupResult(msg) {
    // console.log(msg);
    this.setState({ toastMsg: msg, toastShow: true });
  }

  toggleMobileNav() {
    this.setState({ mobileNavHidden: !this.state.mobileNavHidden });
  }

  toggleToast() {
    this.setState({ toastShow: !this.state.toastShow });
  }

  render() {
    // content for demo
    // this data will normally be held in a server cand axios called on mount.
    let contentObject = {
      paragraphOne: {
        h2Tag: "Furniture you're going to love.",
        pTag:
          "Our products are available through authorized showrooms, on-line retailers and the Urban Woods website. If there is not a dealer near you then we encourage you to contact us directly for more information.",
        pTag2:
          "Reclaimed wood has many advantages. The grain of the timber has tight growth rings that show the superior density of the wood. The natural patina and color of the old-growth timber is preserved in the manufacturing process.  The results provide a look and feel that can not be replicated in new wood.",
        pTag3:
          "The reclaimed wood was originally harvested and milled 50 to 100 years ago, and has been seasoned in sunny Southern California for decades. The vintage wood has better stability and resistance to future distortion or structural movement.",
      },
    };

    if (this.props.user) {
      return (
        <StyledRoot className="commercial_root">
          <HeroSmart
            login={"Peter"}
            backgroundName={
              "https://bobwehadababyitsaboy.s3-eu-west-1.amazonaws.com/hero_commercial.jpg"
            }
            title="Commercial"
            subTitle="From one local business to another"
          />
          <NavBar isHidden={this.state.mobileNavHidden} toggle={this.toggleMobileNav} />
          <LayoutBasic
            h2Tag={contentObject.paragraphOne.h2Tag}
            pTag={contentObject.paragraphOne.pTag}
            pTag2={contentObject.paragraphOne.pTag2}
            pTag3={contentObject.paragraphOne.pTag3}
          />

          <SmartSlider smartArray={this.state.sliderArray} />
          <LayoutBasic
            h2Tag={contentObject.paragraphOne.h2Tag}
            pTag={contentObject.paragraphOne.pTag}
            pTag2={contentObject.paragraphOne.pTag2}
            pTag3={contentObject.paragraphOne.pTag3}
          />
          <Signup submitResult={this.handleSignupResult} />
          <Footer />
          <Toast show={this.state.toastShow} onClose={this.toggleToast}>
            {this.state.toastMsg.map((element) => {
              return <p>{element}</p>;
            })}
          </Toast>
        </StyledRoot>
      );
    } else {
      return (
        <StyledRoot className="commercial_root">
          <HeroSmart
            login={false}
            backgroundName={
              "https://bobwehadababyitsaboy.s3-eu-west-1.amazonaws.com/hero_commercial.jpg"
            }
            title="Commercial"
            subTitle="From one local business to another"
          />
          <NavBar isHidden={this.state.mobileNavHidden} toggle={this.toggleMobileNav} />
          <LayoutBasic
            h2Tag={contentObject.paragraphOne.h2Tag}
            pTag={contentObject.paragraphOne.pTag}
            pTag2={contentObject.paragraphOne.pTag2}
            pTag3={contentObject.paragraphOne.pTag3}
          />
          <SmartSlider smartArray={this.state.sliderArray} />
          <LayoutBasic
            h2Tag={contentObject.paragraphOne.h2Tag}
            pTag={contentObject.paragraphOne.pTag}
            pTag2={contentObject.paragraphOne.pTag2}
            pTag3={contentObject.paragraphOne.pTag3}
          />
          <Signup submitResult={this.handleSignupResult} />
          <Footer />
          <Toast show={this.state.toastShow} onClose={this.toggleToast}>
            {this.state.toastMsg.map((element) => {
              return <p>{element}</p>;
            })}
          </Toast>
        </StyledRoot>
      );
    }
  }
}

const StyledRoot = styled.main`
  position: relative;
`;
